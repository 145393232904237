import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/common/jwt'

const API_URL = process.env.VUE_APP_BASE_API || 'localhost:9000'

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = JwtService.getToken();
  },
  query(resource, params) {
    return Vue.axios.get(resource, params);
  },
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`);
  },
  post(resource, params, config) {
    return Vue.axios.post(`${resource}`, params, config);
  },
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  delete(resource, params) {
    return Vue.axios.delete(resource, params);
  },
  download(resource, config){
    return Vue.axios.get(resource, config);
  }
};
export default ApiService;

//账号服务
export const AccountService = {
  get(resource, slug) {
    return ApiService.get('/account'+ resource, slug);
  },
  create(resource, params) {
    return ApiService.post('/account'+ resource, params);
  },
  update(slug, params) {
    return ApiService.update('/account', slug, params);
  },
  remove(resource) {
    return ApiService.delete('/account'+ resource);
  }
};
//设备服务
export const AssetService = {
  get(resource, slug) {
    return ApiService.get('/asset'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/asset'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/asset'+ resource, params);
  },
  enable(slug, params) {
    return ApiService.put('/asset'+slug+'enable/'+ params);
  },
  batch(params) {
    return ApiService.put('/asset/batch', params)
  },
  destroy(slug) {
    return ApiService.delete('/asset/'+ slug);
  },
  lock(slug, params) {
    return ApiService.put('/asset/lock/'+slug+'/'+ params);
  },
};
//分支机构服务
export const BranchService = {
  get(resource, slug) {
    return ApiService.get('/branch'+ resource, slug);
  },
  update(slug, params) {
    return ApiService.update('/branch', slug, params);
  },
  create(resource, params) {
    return ApiService.post('/branch'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/branch/'+ slug);
  },
  order(params) {
    return ApiService.update("/branch", 'order', params)
  },
  block(slug, params) {
    return ApiService.put('/branch/block/'+slug+'/'+ params);
  }
};
//知识目录服务
export const CatalogKnowledgeService = {
  get(resource, slug) {
    return ApiService.get('/knowledge/catalog'+ resource, slug);
  },
  update(slug, params) {
    return ApiService.update('/knowledge/catalog', slug, params);
  },
  create(resource, params) {
    return ApiService.post('/knowledge/catalog'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/knowledge/catalog/'+ slug);
  },
  order(params) {
    return ApiService.update("/knowledge/catalog", 'order', params)
  },
};
//服务目录服务
export const CatalogService = {
  get(resource, slug) {
    return ApiService.get('/catalog'+ resource, slug);
  },
  update(slug, params) {
    return ApiService.update('/catalog', slug, params);
  },
  create(resource, params) {
    return ApiService.post('/catalog'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/catalog/'+ slug);
  },
  order(params) {
    return ApiService.update("/catalog", 'order', params)
  },
  block(slug, params) {
    return ApiService.put('/catalog/block/'+slug+'/'+ params);
  }
};
//部门管理
export const DepartmentService = {
  get(resource, slug) {
    return ApiService.get('/department'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/department'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/department'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/department/'+ slug);
  }
};
//经销商服务
export const DealerService = {
  get(resource, slug) {
    return ApiService.get('/dealer'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/dealer'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/dealer'+ resource, params);
  },
  delete(slug) {
    return ApiService.delete('/dealer/'+ slug);
  },
  auth(slug) {
    return ApiService.update('/dealer/auth', slug);
  },
  block(slug, params) {
    return ApiService.put('/dealer/block/'+slug+'/'+ params);
  },
  bookmark(slug, params) {
    return ApiService.put('/dealer/bookmark/'+slug+'/'+ params);
  }
};
//员工管理
export const EmployeeService = {
  get(resource, slug) {
    return ApiService.get('/employee'+ resource, slug);
  },
  create(resource, params) {
    return ApiService.post('/employee'+ resource, params);
  },
  update(resource, slug, params) {
    return ApiService.update('/employee'+ resource, slug, params);
  },
  batch(resource, params) {
    return ApiService.put('/employee/batch'+ resource, params)
  },
  destroy(resource) {
    return ApiService.delete('/employee'+ resource);
  },
};
//企业服务
export const EnterpriseService = {
  get(resource, slug) {
    return ApiService.get('/enterprise'+ resource, slug);
  },
  update(slug, params) {
    return ApiService.update('/enterprise', slug, params);
  },
  create(resource, params) {
    return ApiService.post('/enterprise'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/enterprise/'+ slug);
  },
  auth(slug) {
    return ApiService.update('/enterprise/auth', slug);
  },
  block(slug, params) {
    return ApiService.put('/enterprise/block/'+ slug+'/'+ params);
  }
};
//角色分组管理
export const GroupService = {
  get(resource, slug) {
    return ApiService.get('/group'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/group'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/group'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/group/'+ slug);
  }
};
//知识库管理
export const KnowledgeService = {
  get(resource, slug) {
    return ApiService.get('/knowledge'+ resource, slug);
  },
  update(slug, params) {
    return ApiService.update('/knowledge', slug, params);
  },
  create(resource, params) {
    return ApiService.post('/knowledge'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/knowledge'+ slug);
  },
  auth(slug) {
    return ApiService.put('/knowledge/auth/'+ slug);
  },
  top(slug) {
    return ApiService.put('/knowledge/top/'+ slug);
  },
};
//日志服务
export const LogService = {
  get(resource, slug) {
    return ApiService.get('/logs'+ resource, slug);
  },
  add(params) {
    return ApiService.post('/logs', params)
  }
}
//素材管理
export const MaterialService = {
  get(resource, slug) {
    return ApiService.get('/cms/material'+ resource, slug);
  },
  update(slug, params) {
    return ApiService.update('/cms/material', slug, params);
  },
  create(resource, params) {
    return ApiService.post('/cms/material'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/cms/material/'+ slug);
  },
  auth(slug) {
    return ApiService.update('/cms/material/auth', slug);
  },
  block(slug, params) {
    return ApiService.put('/cms/material/block/'+ slug+'/'+ params);
  }
};
//订单服务
export const OrderService = {
  get(resource, slug) {
    return ApiService.get('/order'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/order'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/order'+ resource, params);
  },
  delete(slug) {
    return ApiService.delete('/order'+ slug);
  },
};
//服务请求管理
export const CaseService = {
  get(resource, slug) {
    return ApiService.get('/case'+ resource, slug);
  },
  create(resource, params) {
    return ApiService.post('/case'+ resource, params);
  },
  update(resource, slug, params) {
    return ApiService.update('/case'+ resource, slug, params);
  },
  destroy(resource) {
    return ApiService.delete('/case' + resource);
  }
};
//产品外采
export const PurchaseService = {
  get(resource, slug) {
    return ApiService.get('/purchase'+ resource, slug);
  },
  create(resource, params) {
    return ApiService.post('/purchase'+ resource, params);
  },
  update(resource, slug, params) {
    return ApiService.update('/purchase'+ resource, slug, params);
  },
  destroy(resource) {
    return ApiService.delete('/purchase' + resource);
  }
};
//页面管理
export const PageService = {
  get(resource, slug) {
    return ApiService.get('/cms/page'+ resource, slug);
  },
  update(slug, params) {
    return ApiService.update('/cms/page', slug, params);
  },
  destroy(slug) {
    return ApiService.delete('/cms/page/'+ slug);
  },
};
//产品服务
export const ProductService = {
  get(resource, slug) {
    return ApiService.get('/product'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/product'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/product'+ resource, params);
  },
  enable(slug, params) {
    return ApiService.put('/product'+slug+'enable/'+ params);
  },
  destroy(slug) {
    return ApiService.delete('/product/'+ slug);
  }
};
//租赁服务
export const RentService = {
  get(resource, slug) {
    return ApiService.get('/rent'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/rent'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/rent'+ resource, params);
  },
  delete(slug) {
    return ApiService.delete('/rent'+ slug);
  },
};
//报表服务
export const ReportService = {
  get(resource, slug = "") {
    return ApiService.get("/report" + resource, slug);
  },
  update(slug, params) {
    return ApiService.update('/report', slug, params);
  },
};
//角色管理
export const RoleService = {
  get(resource, slug) {
    return ApiService.get('/role'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/role'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/role'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/role/'+ slug);
  }
};
//系统设置
export const SettingService = {
  get(resource, slug) {
    return ApiService.get('/setting'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/setting'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/setting'+ resource, params);
  },
  enable(slug, params) {
    return ApiService.put('/setting'+slug+'enable/'+ params);
  },
  destroy(slug, params) {
    return ApiService.delete('/setting/'+ slug, params);
  }
};
//工具类
export const ToolsService = {
  create(resource, params) {
    return ApiService.post('/tools'+ resource, params);
  },
  get(resource, slug = "") {
    return ApiService.get("/tools" + resource, slug);
  },
  put(resource, params) {
    return ApiService.put('/tools'+ resource, params);
  },
  destroy(resource) {
    return ApiService.delete(resource);
  },
};
//工作流服务
export const WorkflowService = {
  get(resource, slug) {
    return ApiService.get('/workflow'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/workflow'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/workflow'+ resource, params);
  },
  enable(slug, params) {
    return ApiService.put('/workflow'+slug+'enable/'+ params);
  },
  batch(params) {
    return ApiService.put('/workflow/batch', params)
  },
  destroy(slug) {
    return ApiService.delete('/workflow/'+ slug);
  }
};
//贴条服务
export const NotesService = {
  get(resource, slug) {
    return ApiService.get('/notes'+ resource, slug);
  },
  update(slug, params) {
    return ApiService.update('/notes', slug, params);
  },
  create(resource, params) {
    return ApiService.post('/notes'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/notes/'+ slug);
  }
};


