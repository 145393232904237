import ApiService from "@/common/http";
import JwtService from "@/common/jwt";
import SocketService from '@/common/socket';
import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  CREATE_ROUTER,
} from "./actions.type";
import { SET_AUTH, SET_USER, PURGE_AUTH, SET_ERROR, SET_ROUTERS, SET_LOGS } from "./mutations.type";
import { adminRouterMap, dealerRouterMap, constantRouterMap, notFoundRoutes } from '@/router'

const state = {
  user: {},
  employer: {},
  isAuthenticated: !!JwtService.getToken(),
  routes: [],
};

const getters = {
  currentUser: state => state.user,
  currentEmployer: state => state.employer,
  isAuthenticated: state => state.isAuthenticated,
  currentRoutes: state => state.routes,
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append('username', credentials.username);
      form.append('password', credentials.password);
      let param = new URLSearchParams(form);
      ApiService.post("/auth/local", param)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.token);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [CHECK_AUTH](context) {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("/auth/mine")
          .then(({ data }) => {
            context.commit(SET_USER, data);
            resolve(data)
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.message);
            reject(response.data.message)
          });
      } else {
        context.commit(PURGE_AUTH);
        reject({message:'token is null'})
      }
    })
  },
  [CREATE_ROUTER](context, account) {
    return new Promise((resolve, reject) => {
      let accessedRouters,
        accountRoles,
        dealerAdmin = false;
      accountRoles = account.employer && account.employer.roles;

      accountRoles.forEach(role => {
        if (role.type === 9 && account.employer.ownerType === 'Dealer') dealerAdmin = true;
      });
      if (account.hasAdmin.includes('admin')){
        accessedRouters = adminRouterMap || [];
      } else if (dealerAdmin) {
        accessedRouters = dealerRouterMap || [];
      } else {
        if (account.employer.ownerType === 'Dealer') {
          accessedRouters = filterAsyncRouter(dealerRouterMap, account.permission)
        } else {
          context.commit(SET_LOGS, { operator: state.user._id, action: 'Logout', objType: 'Account', objId: [state.user._id] });
          context.commit(PURGE_AUTH)
          reject({message:'经销商平台暂不提供企业登录。'})
        }

        // accessedRouters = filterAsyncRouter(dealerRouterMap, account.employer.roles);
      }
      context.commit(SET_ROUTERS, accessedRouters);
      resolve(accessedRouters)
    })
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      context.commit(SET_LOGS, { operator: state.user._id, action: 'Logout', objType: 'Account', objId: [state.user._id] });
      context.commit(PURGE_AUTH)
      resolve()
    })
  }
};

const mutations = {
  [SET_AUTH](state, token) {
    state.isAuthenticated = true;
    JwtService.saveToken(token);

  },
  [SET_USER](state, data) {
    let arrPermission = [], arrService = [];
    data.employer.roles.forEach(item => {
      arrPermission.push(item.permission)
      arrService.push(item.service)
    })
    state.user = Object.assign(data, { permission: [...new Set(arrPermission.flat())], service: [...new Set(arrService.flat())] });
    state.employer = data.employer;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = null;
    state.employer = null;
    JwtService.destroyToken();
    SocketService.disconnect();
  },
  [SET_ROUTERS](state, routers) {
    state.routes = constantRouterMap.concat(routers, notFoundRoutes);
  }
};

/**
 * 通过route.name判断是否与当前用户权限匹配
 * @param permission
 * @param route
 */
function hasPermission(permission, route) {

  if (route.name) {
    // console.log('someItem=>'+ permission.includes(route.name))
    // return permission.some(item => route.name.includes(item))
    return permission.includes(route.name)
  } else {
    return true
  }
}

/**
 * 递归过滤异步路由表，返回符合角色权限的路由表
 * @param routes asyncRouterMap
 * @param permission
 */
function filterAsyncRouter(routes, permission) {
  if (permission && permission.length) {
    permission.forEach(item => {
      if (item.substring(0, item.indexOf('_'))) {
        permission.push(item.substring(0, item.indexOf('_'))+'_Line')
        permission.push(item.substring(0, item.indexOf('_')))
      }
    })
  }
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission([...new Set(permission)], tmp)) {
      // if (tmp.children) {
      //   tmp.children = filterAsyncRouter(tmp.children, permission)
      // }
      res.push(tmp)
    }
  })
  return res
}

export default {
  state,
  actions,
  mutations,
  getters
};
