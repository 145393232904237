import Vue from "vue";
import m from 'moment'
m.locale('zh-cn'); // 使用中文
// Filter - FormatTime
// DateTime > '2019/1/31 00:00:00'
Vue.filter('formatTime', function(val) {
  let time = val;
  if (isNaN(Date.parse(val))) {
    if (val) time = val.replace(/-/g, '/').replace(/T/g, ' ');
  }

  let date = new Date(time);
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  const t1 = [year, month, day].map(formatNumber).join('/')
  const t2 = [hour, minute, second].map(formatNumber).join(':')

  return `${t1} ${t2}`
});
// Filter - fromDate
// String > 4 年前
Vue.filter('fromDate', function (val) {
  let date = new Date(val);
  return m(date).fromNow();
});

// Filter - FormatDate
// Date > 'YYYY-MM-DD'
Vue.filter('formatDate', function (val) {
  if (val) {
    let value = new Date(val);
    let year = value.getFullYear();
    let month = padDate(value.getMonth() + 1);
    let day = padDate(value.getDate());
    return year +'-'+ month +'-'+ day;
  }
});

// Filter - Repair Code to Name
Vue.filter('repairStatus', function (val) {
  if (val === 0) return '待受理';
  if (val === 1) return '检测中/维修中';
  if (val === 2) return '维修完成';
  if (val === 3) return '待返回/待取机';
  if (val === 4) return '服务结束';
  if (val === 8) return '取消服务';
});

// Filter - Status Code to Name
Vue.filter('caseStatus', function (val) {
  if (val === 0) return '待受理';
  if (val === 1) return '处理中';
  if (val === 2) return '已取消';
  if (val === 3) return '已取消';
  if (val === 4) return '已完成';
});

Vue.filter('normalStatus', function (val) {
  if (val === 0) return '待处理';
  if (val === 1) return '已完成';
});

Vue.filter('expressState', function (val) {
  if (val === 0) return '在途';
  if (val === 1) return '揽收';
  if (val === 2) return '疑难';
  if (val === 3) return '签收';
  if (val === 4) return '退签';
  if (val === 5) return '派件';
  if (val === 6) return '退回';
  if (val === 7) return '转投';
  if (val === 10) return '待清关';
  if (val === 11) return '清关中';
  if (val === 12) return '已清关';
  if (val === 13) return '清关异常';
  if (val === 14) return '拒签';
})

// Filter - toCNText
// String > 账户
Vue.filter('stringModule', function (val) {
  if (val === 'Account') return '人员';
  if (val === 'Asset') return '资产';
  if (val === 'Produce') return '产品';
  if (val === 'Workflow') return '流程';
  if (val === 'white') return '纯白色';
  if (val === 'primary') return '倍升灰';
  if (val === 'transparent') return '透明色';
  if (val === 'display-4') return '4号标题';
  if (val === 'display-3') return '3号标题';
  if (val === 'display-2') return '2号标题';
  if (val === 'display-1') return '1号标题';
  if (val === 'headline') return '大标题';
  if (val === 'title') return '标题';
  if (val === 'subheading') return '子标题';
  if (val === 'red') return '标准红';
  if (val === 'info') return '标准黑';
  if (val === 'secondary') return '倍升红';
  if (val === 'purple') return '标准紫';
  if (val === 'deep-purple') return '深紫色';
  if (val === 'indigo') return '靛蓝色';
  if (val === 'blue') return '标准蓝';
  if (val === 'light-blue') return '浅蓝色';
  if (val === 'cyan') return '青蓝色';
  if (val === 'teal') return '水绿色';
  if (val === 'green') return '标准绿';
  if (val === 'light-green') return '浅绿色';
  if (val === 'lime') return '柠檬色';
  if (val === 'yellow') return '标准黄';
  if (val === 'amber') return '琥珀色';
  if (val === 'orange') return '橘橙色';
  if (val === 'deep-orange') return '深橙色';
  if (val === 'brown') return '棕褐色';
  if (val === 'blue-grey') return '青灰色';
  if (val === 'grey') return '标准灰';
  if (val === 'primary') return '倍升灰';
  if (val === 'white') return '纯白色';
  if (val === '#f5f5f5') return '浅灰色';
  if (val === 'start') return '开始位置';
  if (val === 'center') return '中间位置';
  if (val === 'end') return '结尾位置';
  if (val === 'baseline') return '起点位置';
  if (val === 'stretch') return '拉伸';
  if (val === 'space-around') return '居中分散';
  if (val === 'space-between') return '两端分散';
  if (val === 'Create') return '创建';
  if (val === 'Update') return '修改';
  if (val === 'Delete') return '删除';
  if (val === 'Sequence') return '串行';
  if (val === 'Synchronization') return '并行';
  if (val === 'Choice') return '执行其一';
  if (val === 'Account') return '人员';
  if (val === 'Asset') return '资产';
  if (val === 'Product') return '产品';
  if (val === 'Task') return '任务';
})


function padDate (va) {
  va = va < 10 ? '0'+va : va;
  return va;
}
function formatNumber(n) {
  const str = n.toString()
  return str[1] ? str : `0${str}`
}

/**
 * @description 格式化金额
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 */
Vue.filter('formatMoney', function (value, str) {
  if (!str) str = '';
  // str 规定货币类型
  if (value == "0") return '0.00';
  if(value == ".") return '';
  if (!value) return '';
  let val = Number(value).toFixed(2) // 提前保留两位小数
  let intPart = parseInt(val) // 获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断 ？？？
  let floatPart = '.00' // 预定义小数部分
  val = val.toString() // 将number类型转为字符串类型，方便操作
  let value2Array = val.split('.')
  if (value2Array.length === 2) { // =2表示数据有小数位
    floatPart = value2Array[1].toString() // 拿到小数部分
    if (floatPart.length === 1) { // 补0,实际上用不着
      return str + intPartFormat + '.' + floatPart + '0'
    } else {
      return str + intPartFormat + '.' + floatPart
    }
  } else {
    return str + intPartFormat + floatPart
  }
})
// 100.1234
Vue.filter('formatMoneyDecimal', function (value, str) {
  if (!str) str = '';
  // str 规定货币类型
  if (value == "0") return '0.0000';
  if (value == ".") return '';
  if (!value) return '';
  let val = Number(value).toFixed(4) // 提前保留两位小数
  let intPart = parseInt(val) // 获取整数部分
  let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断 ？？？
  let floatPart = '.0000' // 预定义小数部分
  val = val.toString() // 将number类型转为字符串类型，方便操作
  let value2Array = val.split('.')
  if (value2Array.length === 2) { // =2表示数据有小数位
    floatPart = value2Array[1].toString() // 拿到小数部分
    if (floatPart.length === 1) { // 补0,实际上用不着
      return str + intPartFormat + '.' + floatPart + '0'
    } else {
      return str + intPartFormat + '.' + floatPart
    }
  } else {
    return str + intPartFormat + floatPart
  }
})

//15亿6204万5698.88
Vue.filter('formatMoneyUnitFloat', function (val) {
  // 防止空字符返回为0
  if (val === '' || !val) return val
  let toNumberVal = Number(val)
  if (isNaN(toNumberVal)) {
    return val
  }
  let flag = ''
  if (toNumberVal < 0) {
    flag = '-'
    toNumberVal = Math.abs(toNumberVal)
  }
  if (toNumberVal >= 10000) {
    // 大于等于10000的数据要去掉小数位
    const numStrArr = String(toNumberVal).split('.')
    const numStr = numStrArr[0]
    const len = numStr.length
    const numArr = []
    // 每4位截取一次，截取小于10000部分的数据
    let itemLast = Number(numStr.substring(len - 4, len))
    // 只有个位数前面要加0 等于0则去掉，不加入数组拼接
    if (itemLast < 10 && itemLast > 0) {
      numArr.unshift('0' + itemLast)
    } else if (itemLast >= 10) {
      numArr.unshift(itemLast)
    }
    let itemCenter = 0
    let itemFirst = 0
    if (len <= 8) {
      // 长度小于等于8， 没有亿位，这个时候万位可以从index = 0 开始截取
      itemCenter = Number(numStr.substring(0, len - 4))
    } else {
      // 长度大于8才有亿， 万位不能再从index = 0开始截取
      itemCenter = Number(numStr.substring(len - 8, len - 4))
      itemFirst = Number(numStr.substring(0, len - 8))
    }
    // 万位和亿位如果大于0才加入数组拼接
    if (itemCenter > 0) numArr.unshift(itemCenter + '万')
    if (itemFirst > 0) numArr.unshift(itemFirst + '亿')
    return flag + numArr.join('') + (numStrArr[1] ? `.${numStrArr[1]}` : '')
  }
  return flag + toNumberVal
})

//15亿6204万5698
Vue.filter('formatMoneyUnitInt', function (val) {
  // 防止空字符返回为0
  if (val === '') return val
  let toNumberVal = Number(val)
  if (isNaN(toNumberVal)) {
    return val
  }
  let flag = ''
  if (toNumberVal < 0) {
    flag = '-'
    toNumberVal = Math.abs(toNumberVal)
  }
  if (toNumberVal >= 10000) {
    // 大于等于10000的数据要去掉小数位
    const numStr = Math.floor(toNumberVal) + ''
    const len = numStr.length
    const numArr = []
    // 每4位截取一次，截取小于10000部分的数据
    let itemLast = Number(numStr.substring(len - 4, len))
    // 只有个位数前面要加0 等于0则去掉，不加入数组拼接
    if (itemLast < 10 && itemLast > 0) {
      numArr.unshift('0' + itemLast)
    } else if (itemLast >= 10) {
      numArr.unshift(itemLast)
    }
    let itemCenter = 0
    let itemFirst = 0
    if (len <= 8) {
      // 长度小于等于8， 没有亿位，这个时候万位可以从index = 0 开始截取
      itemCenter = Number(numStr.substring(0, len - 4))
    } else {
      // 长度大于8才有亿， 万位不能再从index = 0开始截取
      itemCenter = Number(numStr.substring(len - 8, len - 4))
      itemFirst = Number(numStr.substring(0, len - 8))
    }
    // 万位和亿位如果大于0才加入数组拼接
    if (itemCenter > 0) numArr.unshift(itemCenter + '万')
    if (itemFirst > 0) numArr.unshift(itemFirst + '亿')
    return flag + numArr.join('')
  }
  return flag + toNumberVal
})

//15亿6204万5698
Vue.filter('formatMoneyUnit', function (val) {
  // 防止空字符返回为0
  if (val === '') return val
  let toNumberVal = Number(val)
  if (isNaN(toNumberVal)) {
    return val
  }
  let flag = ''
  if (toNumberVal < 0) {
    flag = '-'
    toNumberVal = Math.abs(toNumberVal)
  }
  if (toNumberVal >= 10000) {
    // 大于等于10000的数据要去掉小数位
    const numStr = Math.floor(toNumberVal) + ''
    const len = numStr.length
    const numArr = []
    // 每4位截取一次，截取小于10000部分的数据
    let itemLast = Number(numStr.substring(len - 4, len))
    // 只有个位数前面要加0 等于0则去掉，不加入数组拼接
    if (itemLast < 10 && itemLast > 0) {
      numArr.unshift('0' + itemLast)
    } else if (itemLast >= 10) {
      numArr.unshift(itemLast)
    }
    let itemCenter = 0
    let itemFirst = 0
    if (len <= 8) {
      // 长度小于等于8， 没有亿位，这个时候万位可以从index = 0 开始截取
      itemCenter = Number(numStr.substring(0, len - 4))
    } else {
      // 长度大于8才有亿， 万位不能再从index = 0开始截取
      itemCenter = Number(numStr.substring(len - 8, len - 4))
      itemFirst = Number(numStr.substring(0, len - 8))
    }
    // 万位和亿位如果大于0才加入数组拼接
    if (itemCenter > 0) numArr.unshift(itemCenter + '万')
    if (itemFirst > 0) numArr.unshift(itemFirst + '亿')
    return flag + numArr.join('')
  }
  return flag + toNumberVal
})

//产品可用模块转译
Vue.filter('productEnableModel', function (val) {
  if (!val || val === '') return val
  let arrModels = val
  if (!Array.isArray(val)) arrModels = val.split(',')
  arrModels = arrModels.map(item => {
    if (item === 'rent') return '租赁服务'
    if (item === 'order') return '订单服务'
    if (item === 'repair') return '维修服务'
    return '设备服务'
  })
  return arrModels
})
