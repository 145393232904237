<template>
  <v-card>
    <v-toolbar flat>
      <v-avatar size="52" class="mr-2" tile>
        <v-img
          :src="enterprise.logo_image ? ossURL +'/'+ enterprise.logo_image +'?x-oss-process=image/auto-orient,1/resize,m_lfit,w_125/quality,q_90' : '/static/error/empty_state.svg'"
          aspect-ratio="1" contain>
        </v-img>
      </v-avatar>
      <v-toolbar-title>
        {{enterprise.name}}
        <v-list-item-subtitle v-if="enterprise.creater">状态：{{ enterprise.isBlock ? '暂停运营' : enterprise.status === 1 ? '正常' : '待审批' }} | <widgets-employee-dialogs :account="enterprise.creater._id">{{enterprise.creater.personal.name ? enterprise.creater.personal.name : '*未设置姓名'}}</widgets-employee-dialogs>创建于 {{ enterprise.createTime | formatTime }}</v-list-item-subtitle>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('Close')"><v-icon>close</v-icon></v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style="height: 500px;">
      <v-list subheader>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>vpn_key</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title id="copyTarget">{{ enterprise.invite_code }}</v-list-item-title>
            <v-list-item-subtitle>企业邀请码作为企业员工注册时填写依据。</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <widgets-copy-btn :target="copyCode"/>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="currentUser.employer.ownerId._id === enterprise.account_dealer && (currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Enterprise_Edit'))">
          <v-list-item-icon>
            <v-icon>category</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ enterprise.corp_type && getTypeText(enterprise.corp_type).name }} </v-list-item-title>
            <v-list-item-subtitle>信用代码：{{ enterprise.license_key }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>closed_caption</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ enterprise.name_code }}</v-list-item-title>
            <v-list-item-subtitle>企业代码，用于第三方系统如ERP、CRM、S1等客户标识。</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>bookmark_add</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>ABM：{{ enterprise.additional ? enterprise.additional.abm : '-' }} | ACE: {{ enterprise.additional ? enterprise.additional.ace : '-' }}</v-list-item-title>
            <v-list-item-subtitle>ESC Apple ID: {{ enterprise.additional ? enterprise.additional.esc : '-' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>flag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ enterprise.sub_title }}</v-list-item-title>
            <v-list-item-subtitle>企业理念：企业宣传口号，文化理念</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>contact_phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>联系人：{{ enterprise.contact_name }}</v-list-item-title>
            <v-list-item-subtitle>联系电话：{{ enterprise.contact_phone }} 公开电话：{{ enterprise.public_phone }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>room</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ enterprise.province }} - {{ enterprise.city }}</v-list-item-title>
            <v-list-item-subtitle>详细地址：{{ enterprise.address }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="currentUser.employer.ownerId._id === enterprise.account_dealer && (currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Enterprise_Edit'))">
          <v-list-item-icon>
            <v-icon>notifications</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>接收通知：{{ enterprise.notice_email }} - {{ enterprise.notice_phone }}</v-list-item-title>
            <v-list-item-subtitle>企业端接收服务状态变更通知的终端</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="enterprise.account_managers">
          <v-list-item-icon>
            <v-icon>perm_contact_cal</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-if="enterprise.account_managers.length">
              <widgets-employee-dialogs v-for="managers in enterprise.account_managers" :key="managers._id" :account="managers._id" divider="- ">
                {{managers.personal.name ? managers.personal.name : '*未设置姓名'}}
              </widgets-employee-dialogs>
            </v-list-item-title>
            <v-list-item-subtitle>企业的客户或销售经理</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="currentUser.employer.ownerId._id === enterprise.account_dealer && (currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Enterprise_Edit'))">
          <v-list-item-icon>
            <v-icon>vertical_align_top</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-if="enterprise.limit">最大员工数：{{ enterprise.limit.employees }} - 最大设备数量：{{ enterprise.limit.assets }}</v-list-item-title>
            <v-list-item-subtitle>企业最大支持服务数量，超出可以联系经销商进行调整。</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>how_to_reg</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>员工注册：{{ enterprise.self_register ? '允许' : '禁止' }}</v-list-item-title>
            <v-list-item-subtitle>企业员工是否可以通过邀请码在ESP平台进行自助注册。</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>devices</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>设备管理：{{ enterprise.self_asset ? '允许' : '禁止' }}</v-list-item-title>
            <v-list-item-subtitle>企业是否具备资产管理权限，可自行修改设备信息。</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Lease_Product') || currentUser.permission.includes('Lease_Admin') || currentUser.permission.includes('Lease_Audit')">
          <v-list-item-icon>
            <v-icon>credit_card</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>授信额度系统计算： {{ enterprise.credit_line_sys | formatMoneyUnitFloat }} - 人工调整：{{ enterprise.credit_line_adjust | formatMoneyUnitFloat }}</v-list-item-title>
            <v-list-item-subtitle>授信额度仅对租赁团队可见并使用，在企业编辑中可以调整。</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if="currentUser.employer.ownerId._id === enterprise.account_dealer && (currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Enterprise_Edit'))">
      <v-btn text @click="$router.push({path:'/dealer/workbench?enterprise='+ enterprise._id})">工单-{{enterprise.case_count}}</v-btn>
      <v-btn text @click="$router.push({path:'/dealer/asset/list?enterprise='+ enterprise._id})">设备-{{enterprise.asset_count}}</v-btn>
      <v-btn text @click="$router.push({path:'/dealer/employee?enterprise='+ enterprise._id})">员工-{{enterprise.employee_count}}</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="$emit('Close')">关闭</v-btn>
      <v-btn color="secondary" text @click="$router.push({path:'/dealer/enterprise/edit/'+enterprise._id})">编辑</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import Util from '@/common/util';
import { mapGetters } from "vuex";
import {
  FETCH_ENTERPRISE,
} from "@/store/actions.type";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'enterprise']),
    inputTypedef () {
      return Util.categories('corpType')
    },
  },
  mounted() {
    this.getEnterprise(this.id)
  },
  methods: {
    getEnterprise(id) {
      this.$store.dispatch(FETCH_ENTERPRISE, id)
    },
    getTypeText(code) {
      return this.inputTypedef.find(item => item.code === code)
    },
    copyCode() {
      return this.$el.querySelector('#copyTarget')
    }
  },
}
</script>
