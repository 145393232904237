import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import account from "./account.module";
import dealer from "./dealer.module";
import branch from "./branch.module";
import catalog from "./catalog.module";
import department from "./department.module";
import group from "./group.module";
import role from "./role.module";
import employee from "./employee.module";
import enterprise from "./enterprise.module";
import material from "./material.module";
import page from "./page.module";
import service from "./service.module";
import product from "./product.module";
import asset from "./asset.module";
import tools from "./tools.module";
import setting from "./setting.module";
import workflow from "./workflow.module";
import cases from "./case.module"
import report from "./report.module";
import error from './error.module';
import knowledge from './knowledge.module';
import knowledge_catalog from './knowledge_catalog.module';
import monthly from './monthly.module';
import rent from './rent.module';
import earnings from './earnings.module';
import rent_setting from './rent_setting.module';
import logs from './logs.module';
import release from './release.module';
import order from './order.module';
import purchase from './purchase.module';
import notes from './notes.module';
import theme from './theme.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth, account, dealer, branch, catalog, department, group, role,
    employee, product, report, asset, enterprise, material, page,
    service, tools, setting, workflow, cases, error, logs, knowledge, knowledge_catalog,
    monthly, rent, rent_setting, earnings, release, order, notes, theme, purchase
  }
});
